import { createStore } from "vuex"

import accounts from './modules/accounts'

import content from './modules/content'

import invitations from './modules/invitations'

import teamMembers from './modules/teamMembers'

import venues from './modules/venues'
import users from './modules/users'
import perks from './modules/perks'
import onboardingperks from './modules/onboardingperks'
import perkCategories from './modules/perkCategories'
import productCategories from './modules/productCategories'
import userPerks from './modules/userPerks'
import partnerships from './modules/partnerships'
import promoCodes from './modules/promoCodes'
import memberCards from './modules/memberCards'
import memberCardCategories from "./modules/memberCardCategories"
import memberCardBenefits from "./modules/memberCardBenefits"
import memberCardPerks from "./modules/memberCardPerks"
import memberships from './modules/memberships'
import membershipPerks from './modules/membershipPerks'
import campaigns from './modules/campaigns'
import userConsents from './modules/userConsents'
import userMemberCards from './modules/userMemberCards'
import userTerms from './modules/userTerms'
import pools from './modules/pools'
import ambassadorGroups from './modules/ambassadorGroups'
import roles from './modules/roles'
import idam from "./modules/idam"
import subscriptions from "./modules/subscriptions"
import userSubscriptionEvents from "./modules/userSubscriptionEvents"
import userNotifications from "./modules/userNotifications"
import notifications from "./modules/notifications"
import unsubscribeOptions from "./modules/unsubscribeOptions"
import appContents from "./modules/appContents"
import appGames from "./modules/appGames"
import statistics from "./modules/statistics"
import userPayments from "./modules/userPayments"

import files from "./modules/files"
import catchOffers from "./modules/catchOffers"

const store = createStore({
   state: {
      name: "NightPay"
   },
   modules: {
      accounts,

      content,
      appContents,
      appGames,

      invitations,

      teamMembers,
      unsubscribeOptions,
      venues,
      users,
      perks,
      onboardingperks,
      perkCategories,
      productCategories,
      userPerks,
      partnerships,
      promoCodes,
      memberCards,
      memberCardCategories,
      memberCardBenefits,
      memberCardPerks,
      memberships,
      membershipPerks,
      campaigns,
      userConsents,
      userMemberCards,
      userTerms,
      pools,
      ambassadorGroups,
      roles,
      subscriptions,
      userSubscriptionEvents,
      userNotifications,
      notifications,
      statistics,
      userPayments,
      files,
      catchOffers,
      // LIFE IDAM
      idam
   }
})

export default store