import * as apiGetStripeSubscription from '@/api/subscriptions/getStripeSubscriptionInfo';
import * as apiUpdateSubscriptionPlan from '@/api/subscriptions/updateStripeSubscriptionPlan';

import * as apiGetBillWerkSubscription from '@/api/subscriptions/getBillWerkSubscriptionInfo';
import * as apiUpdateBillWerkSubscriptionPlan from '@/api/subscriptions/updateBillWerkSubscriptionPlan';


export default {
	namespaced: true,
	state: () => ({
		loading: false,
		detailedSubscriptions: {},
	}),
	mutations: {
		gotSubscription(state, subscription) {
			state.detailedSubscriptions[subscription.subscription.id] = subscription;
		},

		gotBillWerkSubscription(state, subscription) {
			state.detailedSubscriptions[subscription.subscription.handle] = subscription;
		},

		isLoading(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async getSubscription({ commit }, { stripeSubscriptionId }) {
			commit('isLoading', true);

			const result = await apiGetStripeSubscription.get(stripeSubscriptionId);

			if (result) {
				commit('gotSubscription', result);
			}

			commit('isLoading', false);
		},

		async updateSubscriptionPlan({ commit }, { userId, subscriptionPlan }) {
			commit('isLoading', true);

			await apiUpdateSubscriptionPlan.put(userId, subscriptionPlan);

			commit('isLoading', false);
		},

		async ensureSubscription({ commit, dispatch, getters }, { stripeSubscriptionId }) {
			commit('isLoading', true);

			if (!getters.subscription(stripeSubscriptionId)) {
				console.log('Subscription not cached. Getting from API.');
				await dispatch('getSubscription', { stripeSubscriptionId });
			}

			commit('isLoading', false);
		},

		async getBillWerkSubscription({ commit }, { billwerkSubscriptionHandle }) {
			commit('isLoading', true);

			const result = await apiGetBillWerkSubscription.get(billwerkSubscriptionHandle);

			if (result) {
				console.log('gotBillWerkSubscription', result);
				commit('gotBillWerkSubscription', result);
			}

			commit('isLoading', false);
		},

		async updateBillWerkSubscriptionPlan({ commit }, { userId, subscriptionPlan }) {
			commit('isLoading', true);

			await apiUpdateBillWerkSubscriptionPlan.put(userId, subscriptionPlan);

			commit('isLoading', false);
		},

		async ensureBillWerkSubscription({ commit, dispatch, getters }, { billwerkSubscriptionHandle }) {
			commit('isLoading', true);

			if (!getters.subscription(billwerkSubscriptionHandle)) {
				console.log('Subscription not cached. Getting from API.');
				await dispatch('getBillWerkSubscription', { billwerkSubscriptionHandle });
			}

			commit('isLoading', false);
		},
	},
	getters: {
		subscription: (state) => (subscriptionId) => {
			return state.detailedSubscriptions[subscriptionId]?.subscription;
		},
		subscriptionInvoices: (state) => (subscriptionId) => {
			return state.detailedSubscriptions[subscriptionId]?.invoices;
		},
		billwerkSubscription: (state) => (subcriptionHandle) => {
			return state.detailedSubscriptions[subcriptionHandle]?.subscription;
		},
		billwerkSubscriptionInvoices: (state) => (subcriptionHandle) => {
			return state.detailedSubscriptions[subcriptionHandle]?.invoices;
		},
		isLoading: (state) => state.loading,
	},
};
