export default [
    {
        title: "Dashboard",
        icon: "fa fa-info-circle",
        slug: "/dashboard",
        group: "main",
        permission: "np.backoffice.dashboard.show"
    },
    {
        title: "Users",
        icon: "fa fa-users",
        slug: "/users",
        group: "main",
        permission: "np.backoffice.users.show"
    },

    {
        title: "Venues",
        icon: "fa fa-store",
        slug: "/venues",
        group: "main",
        permission: "np.backoffice.venues.show"
    },

    // {
    //     title: 'Orders',
    //     icon: 'fa fa-file-invoice',
    //     slug: '/orders'
    // })

    {
        title: "Perks",
        icon: "fa fa-ticket",
        slug: "/perks",
        group: "main",
        permission: "np.backoffice.perks.show"
    },

    {
        title: "Onboarding Perks",
        icon: "fa fa-sign-in-alt",
        slug: "/onboardingperks",
        group: "main",
        permission: "np.backoffice.perks.show"
    },

    {
        title: "Promo Codes",
        icon: "fa fa-dollar",
        slug: "/promo-codes",
        group: "main",
        permission: "np.backoffice.promocodes.show"
    },

    {
        title: "Partnerships",
        icon: "fa fa-heart",
        slug: "/partnerships",
        group: "main",
        permission: "np.backoffice.partnerships.show"
    },

    // {
    //   title: "User Terms",
    //   icon: "fa fa-signature",
    //   slug: "/user-terms",
    // });

    {
        title: "Member Cards",
        icon: "fa fa-credit-card",
        slug: "/member-cards",
        group: "main",
        permission: "np.backoffice.membercards.show"
    },

    {
        title: "Memberships",
        // icon: "fas fa-money-check",
        icon: "far fa-address-card",
        slug: "/memberships",
        group: "main",
        permission: "np.backoffice.memberships.show"
    },

    {
        title: "Catch Offers",
        // icon: "fas fa-money-check",
        icon: "fa fa-percent",
        slug: "/catchoffers",
        group: "main",
        permission: "np.backoffice.memberships.show"
    },

    {
        title: "App Content",
        // icon: "fas fa-money-check",
        icon: "pi pi-file",
        slug: "/app-content",
        group: "main",
        permission: "np.backoffice.perks.show"
    },
    {
        title: "App Notifications",
        // icon: "fas fa-money-check",
        icon: "pi pi-send",
        slug: "/notifications",
        group: "main",
        permission: "np.backoffice.memberships.show"
    },
    {
        title: "App Games",
        // icon: "fas fa-money-check",
        icon: "fa fa-gamepad",
        slug: "/games",
        group: "main",
        permission: "np.backoffice.memberships.show"
    },

    {
        title: "Roles",
        icon: "fa fa-user-tag",
        slug: "/roles",
        group: "main",
        permission: "np.backoffice.roles.show"
    },

    // ONLINEPOS
    {
        title: "Campaigns",
        icon: "fas fa-rocket",
        slug: "/campaigns",
        group: "onlinepos",
        permission: "np.backoffice.campaigns.show"
    },

    // TEST
    {
        title: "Membercard",
        icon: "fas fa-graduation-cap",
        slug: "/test",
        group: "test",
        permission: "np.backoffice.memberships.show"
    },

    // ACCOUNT
    {
        title: "Team",
        icon: "fa fa-users",
        slug: "/settings/team",
        group: "account",
        permission: "np.backoffice.team.show"
    },

    {
        title: "Developers",
        icon: "fa fa-code",
        slug: "/developers",
        group: "account",
        permission: "np.backoffice.administration.manage"
    }
]
